<template>
  <div class="warehouse-detail" v-if="category">
    <breadcrumb
      :items="category.navigationTypeId != 0 ? breadcrumbs : breadcrumb"
    />

    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1 position-1"
    />

    <div class="warehouse-info-container mt-0 mt-md-4">
      <v-container fluid class="py-0">
        <v-row v-if="warehouse" dense class="align-self-center mb-4">
          <v-col md="4" class="py-0">
            <WarehouseInfo :warehouse="warehouse" :is-detail="true" />
          </v-col>
          <v-col md="8" class="warehouse-leaflet-slider pa-4">
            <h3 class="mb-2">Volantini</h3>
            <leaflet-slider :parentWarehouseId="warehouse.warehouseId" />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="warehouse-services mt-n4">
      <v-container>
        <div
          class="d-flex flex-column flex-sm-row justify-space-between align-center"
        >
          <h2 class="mt-2 mb-4">Servizi spesa</h2>
          <v-btn
            depressed
            color="primary"
            text
            link
            class="all-services-link"
            :to="{ name: 'Page', params: { postName: 'servizi-spesa' } }"
          >
            Tutti i servizi spesa
          </v-btn>
        </div>
        <swiper
          class="pa-4 d-flex services-swiper"
          :options="swiperOption"
          ref="swiperRef"
        >
          <swiper-slide
            v-for="service in services"
            :key="service.warehouseClassId"
          >
            <v-card class="fill-height" width="230">
              <v-card-title>{{ service.name }}</v-card-title>
              <v-card-text>{{ service.description }}</v-card-text>
              <!-- <v-card-actions class="service-link">
                  <v-btn text dense small color="primary">Scopri di più</v-btn>
                </v-card-actions> -->
            </v-card>
          </swiper-slide>
        </swiper>
      </v-container>
    </div>
    <div class="warehouse-departments d-flex justify-center align-center">
      <v-container>
        <h2 class="mt-2 mb-6">Reparti</h2>
        <v-row>
          <v-col class="d-flex">
            <ProposalButton
              v-for="department in departments"
              :key="department.warehouseClassId"
              :proposal="warehouseDepartment(department)"
            ></ProposalButton>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <category-block
      :target="category"
      position="position2"
      class="category-block position-2"
    />
    <category-block
      :target="category"
      position="position3"
      class="category-block position-3"
    />

    <v-container fluid>
      <ebsn-meta
        :target="category"
        path="category_info.FOOTER_DESCRIPTION"
        tag="div"
      />
    </v-container>
    <!-- <FormNewsletter></FormNewsletter> -->
  </div>
</template>
<style lang="scss">
.warehouse-detail {
  .warehouse-green-card,
  .v-icon {
    color: #142a14;
  }
  .warehouse-info-container {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      background: rgb(19, 165, 56);
      background: linear-gradient(
        90deg,
        rgba(19, 165, 56, 1) 0%,
        rgba(19, 165, 56, 1) 26%,
        rgba(255, 255, 255, 1) 26%
      );
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
  .today-hours {
    color: initial !important;
  }
  .warehouse-info {
    padding: 12px;
    .warehouse-phone {
      color: #142a14;
      text-decoration: none;
    }
  }
  .open-alert {
    padding: 2px 4px;
    font-size: 0.9rem;
    margin-top: 8px;
    border-width: 1px !important;
  }
  .calc-distnace-btn {
    color: #142a14;
  }

  .warehouse-services {
    background-color: #f4f2f0;
    .service-link {
      position: absolute;
      bottom: 0px;
      right: 0;
    }
    .services-swiper {
      .swiper-slide {
        width: auto;
        height: auto;
      }
    }
  }

  .warehouse-departments {
    .proposal-btn {
      margin: 0 8px 0 8px;
      width: 120px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>
<script>
// import Breadcrumb from "@/components/navigation/Breadcrumb";
import WarehouseInfo from "@/components/warehouse/WarehouseInfo.vue";
import LeafletSlider from "@/components/leaflet/LeafletsSlider.vue";
// import FormNewsletter from "@/components/wordpress/FormNewsletter.vue";
import ProposalButton from "@/components/categoryBlock/elements/ProposalButton.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import clickHandler from "~/mixins/clickHandler";
import categoryMixins from "~/mixins/category";

import AddressService from "~/service/addressService";

import { gmapApi } from "vue2-google-maps";

export default {
  name: "WarehouseDetail",
  components: {
    WarehouseInfo,
    ProposalButton,
    Breadcrumb,
    LeafletSlider
  },
  mixins: [clickHandler, categoryMixins],
  data() {
    return {
      // warehouseList: [],
      warehouse: null,
      serviceGroupId: 1,
      departmentGroupId: 2,
      services: [],
      departments: [],
      slidesPerView: 3,
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 32,
        autoplay: false,
        loop: false,
        watchOverflow: true,
        pagination: {
          el: `.event-${this.from}`,
          clickable: true
        }
      }
    };
  },
  computed: {
    google: gmapApi,
    mapWidth() {
      return "auto";
    },
    mapHeight() {
      return "480px";
    },
    breadCrumbs() {
      let breadCrumbs = [
        { to: { name: "Home" }, text: "Homepage", exact: true },
        { to: { name: "WarehouseList" }, text: "Punti Vendita", exact: true }
      ];
      if (this.warehouse) {
        breadCrumbs.push({
          to: {
            name: "WarehouseDetail",
            params: {
              warehouseId: this.warehouse.warehouseId
            }
          },
          text: this.warehouse.name,
          exact: true
        });
      }
      return breadCrumbs;
    }
  },
  methods: {
    async reload(warehouseId) {
      try {
        let res = await AddressService.getWarehouseById(warehouseId);
        this.warehouse = res;

        this.services = this.warehouse.warehouseClass.filter(
          wc =>
            wc.warehouseClassGroup.warehouseClassGroupId === this.serviceGroupId
        );

        this.departments = this.warehouse.warehouseClass.filter(
          wc =>
            wc.warehouseClassGroup.warehouseClassGroupId ===
            this.departmentGroupId
        );
      } catch (err) {
        console.log(err);
      }
    },
    warehouseDepartment(department) {
      return {
        img: department.iconSource,
        imgAlt: department.name,
        imgDescription: department.name,
        content: department.name,
        metaData: {
          category_proposal_type: { BG_ICON_COLOR: "var(--v-primary-base)" }
        }
      };
    }
  },
  mounted() {
    this.reload(this.$route.params.warehouseId);
  },
  watch: {
    $route() {
      this.reload(this.$route.params.warehouseId);
    }
  }
};
</script>
